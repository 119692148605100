/** @format */

import { React } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  quantityChanged,
  productRemoved,
  updateCart,
} from "../../redux/reducers/cart";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

import QuantitySelector from "../../components/quantitySelector";

const CORPORATE_PRODUCT_TYPES = [
  "branded-cake",
  "number-cake",
  "name-cake",
  "number-cupcake",
  "individual-box",
  "group-box",
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "50px !important",
    textAlign: "center",
    ...theme.textStyle,
  },
  subHeading: {
    color: theme.palette.secondary.main.main,
    margin: "20px 0 60px 0 !important",
    ...theme.textStyle,
  },
  productPic: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
  },
}));

function Product({ products }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const tempEmail = localStorage.getItem("tempEmail");

  const quantitySelectorStyle = {
    icon: {
      fontSize: 15,
      color: "#fd7762",
    },
    quantityText: {
      width: "20px",
      height: "20px",
      fontSize: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0 !important",
      marginRight: "0 !important",
      backgroundColor: "white",
      color: "#fd7762",
    },
  };

  const handleChange = async (quantity, id) => {
    const res = await axios.post("api/cart/updateQuantity", {
      email: currentUser ? currentUser.email : tempEmail,
      id,
      newQuantity: quantity,
    });
    if (res.status === 200) {
      dispatch(quantityChanged({ id, newQuantity: quantity }));
    }
  };

  const handleRemove = async (id) => {
    const res = await axios.post("api/cart/removeProduct", {
      email: currentUser ? currentUser.email : tempEmail,
      id,
    });
    if (res.status === 200) {
      dispatch(productRemoved({ id }));
    }
  };

  return (
    <Paper elevation={10} className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={"h5"} className={classes.sectionTitle}>
            Products
          </Typography>
        </Grid>
        {products.map((product) => {
          return (
            <Grid container sx={{ mb: 5 }}>
              <Grid item xs={5} sm={5} xl={3}>
                {product.imgPath && (
                  <img
                    src={product.imgPath}
                    className={classes.productPic}
                    alt="product-img"
                  />
                )}
              </Grid>
              <Grid item xs={6} sm={6} xl={8}>
                <Typography
                  className={classes.textStyle}
                  sx={{ color: "#fd7762", fontWeight: "bold", mb: 1 }}>
                  {product.name}
                </Typography>
                {product.category && product.category !== "" && (
                  <Typography
                    className={classes.textStyle}
                    sx={{ color: "#828282", mb: 1 }}>
                    {`Type: ${product.category}`}
                  </Typography>
                )}

                {product.type === "grazeBox" && (
                  <Box>
                    {product.style && product.style !== "" && (
                      <Typography
                        className={classes.textStyle}
                        sx={{ color: "#828282", mb: 1 }}>
                        {`Style: ${product.style}`}
                      </Typography>
                    )}
                    {product.diet && product.diet !== "" && (
                      <Typography
                        className={classes.textStyle}
                        sx={{ color: "#828282", mb: 1 }}>
                        {`Dietery Option: ${product.diet}`}
                      </Typography>
                    )}
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      Size: {product.size}
                    </Typography>
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      Packaging: {product.packaging}
                    </Typography>
                  </Box>
                )}

                {product.type === "regular-order-dessert" ? (
                  product.discountedPrice ? (
                    <Typography className={classes.textStyle} sx={{ mb: 1 }}>
                      <span style={{ color: "#828282" }}>{`Price: `}</span>{" "}
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: "#828282",
                        }}>
                        {` ${product.price.toFixed(2)}`}
                      </span>{" "}
                      <span style={{ color: "green" }}>
                        {`${product.discountedPrice.toFixed(2)}/each`}
                      </span>
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      {`Price: ${product.price.toFixed(2)}/each`}
                    </Typography>
                  )
                ) : product.price ? (
                  <Typography
                    className={classes.textStyle}
                    sx={{ color: "#828282", mb: 1 }}>
                    {`Price: $${product.price.toFixed(2)}`}
                  </Typography>
                ) : null}

                {(product.type === "regular-order-dessert" ||
                  product.type === "valentine") &&
                  product.flavor && (
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      {`Flavor: ${product.flavor}`}
                    </Typography>
                  )}

                {(product.type === "custom-cake" ||
                  product.type === "standardCake" ||
                  product.type === "cheesecake" ||
                  product.type === "wedding-cake" ||
                  product.type === "holidayCake" ||
                  CORPORATE_PRODUCT_TYPES.indexOf(product.type) !== -1) && (
                  <Box>
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      Size: {product.size}
                    </Typography>
                    <Typography
                      className={classes.textStyle}
                      sx={{ color: "#828282", mb: 1 }}>
                      Flavor: {product.flavor}
                    </Typography>
                    {product.color && (
                      <Typography
                        className={classes.textStyle}
                        sx={{ color: "#828282", mb: 1 }}>
                        Color: {product.color}
                      </Typography>
                    )}

                    {"customizations" in product &&
                      product.customizations?.map((option) => {
                        if (option.parentDisplayName === "Colors") {
                          const colorBoxes = option.selection
                            .split(",")
                            .map((hex) => (
                              <div
                                style={{
                                  width: 26,
                                  height: 26,
                                  backgroundColor: hex,
                                  margin: "0 2px",
                                }}
                              />
                            ));
                          return (
                            <Typography
                              className={classes.textStyle}
                              sx={{ color: "#828282", mb: 1 }}>
                              <div style={{ display: "flex" }}>
                                <div>{option.parentDisplayName}:</div>
                                {colorBoxes}
                              </div>
                            </Typography>
                          );
                        } else if (option.parentDisplayName === "Logo") {
                          return (
                            <Typography
                              className={classes.textStyle}
                              sx={{ color: "#828282", mb: 1 }}>
                              <div style={{ display: "flex" }}>
                                <div>{option.parentDisplayName}:</div>
                                <img
                                  style={{
                                    height: 26,
                                    width: 26,
                                    paddingLeft: 8,
                                  }}
                                  src={option.selection}
                                />
                              </div>
                            </Typography>
                          );
                        } else {
                          return (
                            <Typography
                              className={classes.textStyle}
                              sx={{ color: "#828282", mb: 1 }}>
                              {option.parentDisplayName}: {option.selection}
                            </Typography>
                          );
                        }
                      })}
                  </Box>
                )}

                <Box sx={{ display: "flex" }}>
                  <Typography
                    className={classes.textStyle}
                    sx={{ color: "#828282", mr: 2, mt: 1 }}>
                    Quantity:
                  </Typography>

                  <QuantitySelector
                    itemsPerSet={
                      product.type === "wedding-dessert"
                        ? product.itemsPerSet
                        : 1
                    }
                    handleQuantityChange={(newQuantity) =>
                      handleChange(newQuantity, product.id)
                    }
                    initialQuantity={product.quantity}
                    minQTY={product.minQTY}
                    styleObj={quantitySelectorStyle}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  onClick={() => handleRemove(product.id)}
                  sx={{ color: "#fd7762", borderColor: "#fd7762" }}></CloseIcon>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { products, productRefs } = state.cart;
  const { size } = state.cake;
  return { products, productRefs, size };
}

export default connect(mapStateToProps)(Product);
